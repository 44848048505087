body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow-x: hidden;
}

.MainBtn {
  background-color: #3d61b0;
}

.MainBtn:hover {
  background-color: #2f4b87;
}

a,
a:visited {
  color: rgb(54, 123, 207);
  width: 100%;
  text-decoration: none;
}

.slick-dots li.slick-active button:before {
  color: #3d61b0;
}

.slick-dots {
  bottom: 0px;
}
.Mui-selected {
  color: #241f21;
  font-weight: 600;
}
.MuiTabs-indicator {
  background-color: #4b4749;
}

.slick-initialized {
  width: 100%;
}

.slick-list {
  
}

.slick-slide img {
  object-fit: center;
  width: auto;
  height: calc(100vh - 330px);
  margin: auto;
}

@media screen and (max-width: 600px) {
  .slick-slide img {
    width: 100%;
    height: 100%;
  }

  .slick-list {
    height: 520px;
  }
}
#app-container {
  max-width: 1200px;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}
#app-container:has(header){
  padding-top: 66px;
}

/* html,

a {
  color: primary;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: white;
  }
} */

.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tool-tip-text {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip .tool-tip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.tooltip .tool-tip-text-dashboard {
  width: 100px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  left: 100%;
  margin-top: -5px;
  margin-left: 10px;
}
.tooltip .tool-tip-text-dashboard::after {
  content: "";
  position: absolute;
  top: 8px;
  right: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555555 transparent transparent;
}
input[type="text"],
input[type="password"],
textarea {
  background-color: #fafbfc !important;
  border: 1px solid #aaa !important;
  border-radius: 8px !important;
}
.MuiInputBase-formControl {
  border-radius: 8px !important;
}

@keyframes floating {
  0% {
    transform: translateY(0%) rotate(-55deg);
  }
  50% {
    transform: translateY(300%) rotate(55deg);
  }
  100% {
    transform: translateY(0%) rotate(-55deg);
  }
}

@keyframes growing {
  0% {
    transform: scale(1) rotate(-55deg);
    opacity: 0.2;
  }
  50% {
    transform: scale(2) rotate(55deg);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(-55deg);
    opacity: 0.2;
  }
}

.MuiBadge-badge.MuiBadge-standard {
  top: 18px !important;
  right: 18px !important;
}